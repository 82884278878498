export default {
  // BASE_FILE_URL: "http://localhost:1337/get-file/",
  BASE_FILE_URL: "https://www.saheart.org/api/get-file/", 
  BASE_BACKEND_URL: "https://www.saheart.org/api",
  // BASE_BACKEND_URL: "http://localhost:1337",
  //Test DETAILS
  // PAYFAST_MERCHANT_ID: "10026202",
  // PAYFAST_MERCHANT_KEY: "f4dk3limnesco",
  // PAYFAST_FORM_URL: "https://sandbox.payfast.co.za/eng/process",

  //Live DETAILS

  PAYFAST_MERCHANT_ID: "15952967",
  PAYFAST_MERCHANT_KEY: "43gcgmpgwfn9g",
  PAYFAST_FORM_URL: "https://www.payfast.co.za/eng/process",
  PAYFAST_PASSPHRASE: "SAh3artMembersPAY",

  // Sage Test Details
  // SAGE_API_URL: "https://resellers.accounting.sageone.co.za/api/2.0.0/",
  // SAGE_API_KEY: "{9EF97F02-AFA2-4995-8F93-F1407BD67308}",
  // SAGE_API_COMPANY_ID: 14312,
  // SAGE_API_USERNAME: "sebastien@remoteon.co.za",
  // SAGE_API_PASSWORD: "S0luti0ns!",

  // Sage Live Details
  SAGE_API_URL: "https://accounting.sageone.co.za/api/2.0.0/",
  SAGE_API_KEY: "{05551899-39A3-4E09-A52E-E279460D58B9}",
  SAGE_API_COMPANY_ID: 535935,
  SAGE_API_USERNAME: "sebastien@remoteon.co.za",
  SAGE_API_PASSWORD: "S0luti0ns!",

  PAYER_TYPES: {
    IN: "individual",
    BE: "billing_entity",
    GP: "group",
  },
  HIDE_RESOURCES: [
    // "Test Inventory 1",
    // "Test Inventory 2",
    // "Congress Sponsorship MedTech regulation",
    // "SA Heart, SIG and Branch fees",
  ],
};
